// Bootsrap custom styles sass

// SJ66 Color Theme Swatches in Hex
$sj66-black: #223440;
$sj66-white: #EBFAFD;
$sj66-orange-lt: #D95829;
$sj66-orange: #BF3326;
$sj66-red: #8C1616;

// Override default Bootstrap variables before the import
// https://getbootstrap.com/docs/4.1/getting-started/theming/#css-variables
// $body-bg: #ffffff;

// Colors
$primary: $sj66-orange-lt;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

// Custom styles

nav {
  padding-top: .3em;
  padding-bottom: .3em;
  margin-bottom: 1.5em;
  border: 0 0 1px 0 solid;
  box-shadow: 5px 10px 15px $sj66-orange;
  font-weight: bold;
  text-align: center;
  background-color: whitesmoke;
}

nav a {
  color: $sj66-black;
}

article {
  text-align: left;
}

footer {
  border-top: 1px solid $sj66-orange;
  font-size: 0.85em;
  text-align: center;
}

.highlight {
  // font-weight: bold;
  color: $sj66-orange;
}

.row {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

// old index.css stuff
// TODO: rm and check if any effect
// TODO: check if any use for separate App.css
body {
  color: $sj66-black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('assets/sj66logo400-bg6.png') center 1rem no-repeat;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// orig. App.css stuff
.App-logo {
  animation: App-logo-spin infinite 30s linear;
  pointer-events: none;
}
.App-header {
  /* min-height: 10vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  // color: white;
}
.App-header h1 {
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
